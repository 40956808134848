import * as React from 'react';

import {
    FeaturesWrapper,
    InfoContainer
} from '../InfoSection/InfoElements';
import {
    OneWrapper,
    ServiceP,
    ServicesCard,
    ServicesH2, SixWrapper,
    ThreeWrapper, TwoWrapper,
    WhiteH1
} from '../forms/FormsElements';
import client1 from '../../images/clients/aamericanflooring-com.png';
import client2 from '../../images/clients/aayfdt-org.png';
import client3 from '../../images/clients/asffinancialonline-com.png';
import client4 from '../../images/clients/christianlifecenter-tv.png';
import client5 from '../../images/clients/dewittclinic-com.png';
import client6 from '../../images/clients/dezerdevelopment-com.png';
import client7 from '../../images/clients/ergis-us.png';
import client8 from '../../images/clients/europeanenergy-com.png';
import client9 from '../../images/clients/federationhousing-org.png';
import client10 from '../../images/clients/focusbroadband-com.png';
import client11 from '../../images/clients/genelux-com.png';
import client12 from '../../images/clients/gphommel-com.png';
import client13 from '../../images/clients/habitatmanagementinc-com.png';
import client14 from '../../images/clients/hhwaz-org.png';
import client15 from '../../images/clients/hoplercpas-com.png';
import client16 from '../../images/clients/hudepohlconstruction-com.png';
import client17 from '../../images/clients/indigorootsstudio-com.png';
import client18 from '../../images/clients/kentucky-gov.png';
import client19 from '../../images/clients/level12-io.png';
import client20 from '../../images/clients/makofabrication-com.png';
import client21 from '../../images/clients/menorafinancial-com.png';
import client22 from '../../images/clients/natare-com.png';
import client23 from '../../images/clients/poeandassociates-com.png';
import client24 from '../../images/clients/primetac-com.png';
import client25 from '../../images/clients/salemcity-org.png';
import client26 from '../../images/clients/sevitahealth-com.png';
import client27 from '../../images/clients/skyglue-com.png';
import client28 from '../../images/clients/theshul-org.png';
import client29 from '../../images/clients/timewisefinancialllc-com.png';
import client30 from '../../images/clients/unitedrealestatedfwproperties-com.png';
import {FaStar} from 'react-icons/fa';

const Star = ({selected = false}) => (
    <FaStar color={selected ? "orange" : "grey"}/>
);

const TestimonialsSection = (testimonials) => {
    return (
        <>
            <InfoContainer lightBg={testimonials.lightBg} id={testimonials.id}>
                <FeaturesWrapper>
                    <WhiteH1>Real Testimonials</WhiteH1>
                    <ThreeWrapper>
                        <ServicesCard>
                            <ServicesH2>Ontana Planet Green</ServicesH2>
                            <div>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                            </div>
                            <ServiceP>
                                All features are quick and easy.
                            </ServiceP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesH2>The Blue Iris</ServicesH2>
                            <div>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                            </div>
                            <ServiceP>
                                Easy to use. Warned of errors.
                            </ServiceP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesH2>Wild Alaska Oils LLC</ServicesH2>
                            <div>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                            </div>
                            <ServiceP>
                                Easy with a Mac.
                            </ServiceP>
                        </ServicesCard>
                    </ThreeWrapper>
                    <OneWrapper>
                        <ServicesCard>
                            <ServicesH2>Sacco Enterprises,Inc.</ServicesH2>
                            <div>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                            </div>
                            <ServiceP>
                                Very easy to use and understand.I will in the future file all my forms electronically, even for the companies that it is not required. So much easier than going through the process of printing, filing, mailing.
                            </ServiceP>
                        </ServicesCard>
                    </OneWrapper>
                    <TwoWrapper>
                        <ServicesCard>
                            <ServicesH2>Holzman & Daw,Inc.</ServicesH2>
                            <div>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={false}/>
                            </div>
                            <ServiceP>
                                Once logged in it was easy to navigate and complete the forms.
                            </ServiceP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesH2>Roadhaven Resort</ServicesH2>
                            <div>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                            </div>
                            <ServiceP>
                                I'm impressed how fast the Customer Service responded.
                            </ServiceP>
                        </ServicesCard>
                    </TwoWrapper>
                    <ThreeWrapper>
                        <ServicesCard>
                            <ServicesH2>Courage LLC</ServicesH2>
                            <div>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                            </div>
                            <ServiceP>
                                Understandable, user friendly.
                            </ServiceP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesH2>EVOLVE AUDIO VISUAL,LLC</ServicesH2>
                            <div>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                            </div>
                            <ServiceP>
                                Easy to use and understand.
                            </ServiceP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesH2>ELO DENTAL PC</ServicesH2>
                            <div>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                            </div>
                            <ServiceP>
                                SPEED.
                            </ServiceP>
                        </ServicesCard>
                    </ThreeWrapper>
                    <TwoWrapper>
                        <ServicesCard>
                            <ServicesH2>Natare Corporation</ServicesH2>
                            <div>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                            </div>
                            <ServiceP>
                                I don't have to print and mail the forms. Enter the info and I'm done.
                            </ServiceP>
                        </ServicesCard>
                        <ServicesCard>
                            <ServicesH2>VOCAL,INC.</ServicesH2>
                            <div>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                                <Star selected={true}/>
                            </div>
                            <ServiceP>
                                Easy to file, but there is too hard file bulk data.
                            </ServiceP>
                            <ServiceP /><ServiceP />
                        </ServicesCard>
                    </TwoWrapper>
                </FeaturesWrapper>

                <FeaturesWrapper>

                    <WhiteH1>Our Clients</WhiteH1>
                    <SixWrapper>
                        <ServicesCard>
                            <a href='https://aamericanflooring.com' target='_blank' rel="noreferrer"><img src={client1} alt='American Flooring'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://aayfdt.org' target='_blank' rel="noreferrer"><img src={client2} alt='American Youth Fotbool'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://asffinancialonline.com' target='_blank' rel="noreferrer"><img src={client3} alt='ASF Financial'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://christianlifecenter.tv' target='_blank' rel="noreferrer"><img src={client4} alt='Christian Life Center'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://dewittclinic.com' target='_blank' rel="noreferrer"><img src={client5} alt='Dewitt Clinic'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://dezerdevelopment.com' target='_blank' rel="noreferrer"><img src={client6} alt='Dezer Development'/></a>
                        </ServicesCard>
                    </SixWrapper>

                    <SixWrapper>
                        <ServicesCard>
                            <a href='https://europeanenergy.com' target='_blank' rel="noreferrer"><img src={client8} alt='European Energy'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://federationhousing.org' target='_blank' rel="noreferrer"><img src={client9} alt='Federation Housing'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://focusbroadband.com' target='_blank' rel="noreferrer"><img src={client10} alt='Focus Broadband'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://genelux.com' target='_blank' rel="noreferrer"><img src={client11} alt='genelux'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://ergis.us' target='_blank' rel="noreferrer"><img src={client7} alt='ERGIS'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://gphommel.com' target='_blank' rel="noreferrer"><img src={client12} alt='hommel'/></a>
                        </ServicesCard>
                    </SixWrapper>

                    <SixWrapper>
                        <ServicesCard>
                            <a href='https://habitatmanagementinc.com' target='_blank' rel="noreferrer"><img src={client13} alt='habitat management'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://hhwaz.org' target='_blank' rel="noreferrer"><img src={client14} alt='Horizen Health and Welness'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://hoplercpas.com' target='_blank' rel="noreferrer"><img src={client15} alt='hopler & compant'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://hudepohlconstruction.com' target='_blank' rel="noreferrer"><img src={client16} alt='Construction Company'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://indigorootsstudio.com' target='_blank' rel="noreferrer"><img src={client17} alt='indigo roots yoga'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://kentucky.gov' target='_blank' rel="noreferrer"><img src={client18} alt='Kentucky State'/></a>
                        </ServicesCard>
                    </SixWrapper>

                    <SixWrapper>
                        <ServicesCard>
                            <a href='https://level12.io' target='_blank' rel="noreferrer"><img src={client19} alt='Level 12'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://makofabrication.com' target='_blank' rel="noreferrer"><img src={client20} alt='Mako Fabrication'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://menorafinancial.com' target='_blank' rel="noreferrer"><img src={client21} alt='Menora Financial'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://natare.com' target='_blank' rel="noreferrer"><img src={client22} alt='Nature Pools'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://poeandassociates.com' target='_blank' rel="noreferrer"><img src={client23} alt='poe and associates'/></a>
                        </ServicesCard>
                        <ServicesCard>
                            <a href='https://primetac.com' target='_blank' rel="noreferrer"><img src={client24} alt='prime tac'/></a>
                        </ServicesCard>
                    </SixWrapper>
                </FeaturesWrapper>
            </InfoContainer>

        </>
    )
}


export default TestimonialsSection;




