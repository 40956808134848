import React from 'react';
import {
    FooterContainer, FooterLink,
    FooterLinkContainer,
    FooterLinkItems,
    FooterLinkWrapper,
    FooterWrap, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights
} from './FooterElements';
import TFALogo from '../../images/TFA-white-logo.png'
const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinkContainer>
                    <FooterLinkWrapper>
                        <FooterLinkItems>
                            {/*<FooterLinkTitle>About Us</FooterLinkTitle>*/}
                                <FooterLink to='/terms' target='_blank'> Terms of Use</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            {/*<FooterLinkTitle>About Us</FooterLinkTitle>*/}
                            <FooterLink to='/privacy-policy' target='_blank'> Privacy Policy</FooterLink>
                        </FooterLinkItems>
                    </FooterLinkWrapper>

                    <FooterLinkWrapper>
                        <FooterLinkItems>
                            <FooterLink to='/ccpa' target='_blank'>CCPA</FooterLink>
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLink  to='/cookies-policy' target='_blank'>Cookies Policy</FooterLink>
                        </FooterLinkItems>
                    </FooterLinkWrapper>
                </FooterLinkContainer>

                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/'><img width='10%' alt='TFA white logo' src={TFALogo}/></SocialLogo>
                        <WebsiteRights> @ {new Date().getFullYear()} TaxFile.App, Tax Form Solutions LLC. All rights reserved</WebsiteRights>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>

        </FooterContainer>
    );
};

export default Footer;
