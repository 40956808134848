import {ServicesContainer, ServicesH1, ServicesWrapper} from "../components/forms/FormsElements";
import React from "react";
import {FaEnvelope, FaPhone} from 'react-icons/fa';
import {BulletTitle, BulletWrapper, TextWrapper} from "../components/InfoSection/InfoElements";


const Contacts = () => {

    return (
        <ServicesContainer id='contacts'>
            <ServicesH1>Contact us</ServicesH1>
            <ServicesWrapper>
                <TextWrapper>
                <BulletWrapper>
                <FaEnvelope/>
                    <BulletTitle>support@taxfile.app</BulletTitle>
                </BulletWrapper>
                <BulletWrapper>
                    <FaPhone/>
                    <BulletTitle>+1 (888) 479-0979 </BulletTitle>
                </BulletWrapper>
                </TextWrapper>
            </ServicesWrapper>
        </ServicesContainer>
    );
};

export default Contacts;
