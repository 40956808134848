import {CardWrapDiscount} from "./DownloadElements";
import {Header} from "../components/PrivacyTerms";
import {ModalSubtitle, ModalText} from "../components/InfoSection/InfoElements";
import React from "react";

export default function Promotion() {
    return (
        <div>
            <CardWrapDiscount>
                <Header> Still Thinking.. </Header>
                <ModalSubtitle>Your first eFile is on us, no credit card required. Just <a href='https://taxfileapp.securetaxfilings.com/register' target='_blank' rel="noreferrer">register</a> and send us an email to support@taxfile.app and we will add 1 eFile credit to your account.</ModalSubtitle>
                <ModalText>We reply in about 20 minutes during the business hours</ModalText>
            </CardWrapDiscount>
        </div>
    )
}