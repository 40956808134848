import * as React from 'react';
import QuickBookIntegration from '../../images/QuickBoooks-and-Xerox-Integration-Feature.png'
import PostalMail from '../../images/Recipients-Postal-Mailing-Feature.png'
import StaffManagement from '../../images/Staff-Managment.png'
import TopSecurity from '../../images/Top-Security.png'

import {
    CardWrap,
    Column1,
    Column2, FeatureRow, FeaturesWrapper,
    InfoContainer,
    Subtitle,
    TextWrapper,
    TopLine
} from '../InfoSection/InfoElements';


const FeaturesSection= (features) => {
    return (
        <>

            <InfoContainer lightBg={features.lightBg} id={features.id}>
                <FeaturesWrapper>
                    <FeatureRow imgStart={features.imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine darkBg={true}>{features.topLine1}</TopLine>
                                <Subtitle lightTextDesc={features.lightTextDesc}>{features.description1}</Subtitle>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <CardWrap>
                                <img width='100%' src={QuickBookIntegration} alt='Quickbooks and Xero Integrations Feature'/>
                            </CardWrap>
                        </Column2>
                    </FeatureRow>
                </FeaturesWrapper>

                <FeaturesWrapper>
                    <FeatureRow imgStart={features.imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine darkBg={true}>{features.topLine3}</TopLine>
                                <Subtitle lightTextDesc={features.lightTextDesc}>{features.description3}</Subtitle>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <CardWrap>
                                <img width='100%' src={PostalMail} alt='Recipients Postal Mailing Feature'/>
                            </CardWrap>
                        </Column2>
                    </FeatureRow>
                </FeaturesWrapper>

                <FeaturesWrapper>
                    <FeatureRow imgStart={features.imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine darkBg={true}>{features.topLine4}</TopLine>
                                <Subtitle lightTextDesc={features.lightTextDesc}>{features.description4}</Subtitle>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <CardWrap>
                                <img width='100%' src={StaffManagement} alt='Staff Management'/>
                            </CardWrap>
                        </Column2>
                    </FeatureRow>
                </FeaturesWrapper>

                <FeaturesWrapper>
                    <FeatureRow imgStart={features.imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine darkBg={true}>{features.topLine5}</TopLine>
                                <Subtitle lightTextDesc={features.lightTextDesc}>{features.description5}</Subtitle>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <CardWrap>
                                <img width='100%' src={TopSecurity} alt='Top Security'/>
                            </CardWrap>
                        </Column2>
                    </FeatureRow>
                </FeaturesWrapper>
            </InfoContainer>

        </>
    )
}


export default FeaturesSection;




