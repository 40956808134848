import styled from "styled-components";
import { Link } from "react-scroll";

export const Button = styled(Link)`
  border-radius: 50px;
  background: ${({primary}) => (primary ? '#FFF' : '#010606')};
  white-space: nowrap;
  padding: ${({big}) => big ? '14px 48px' : '12px 30px'};
  color: ${({dark}) => (dark ? '#fff' : '#010606')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ? '#c3eff6' : '#01BF71')};
  }
`
export const OfferButton = styled(Link)`
  border-radius: 50px;
  background: ${({primary}) => (primary ? '#FFF' : '#01BF71')};
  white-space: nowrap;
  padding: ${({big}) => big ? '14px 48px' : '12px 30px'};
  color: ${({dark}) => (dark ? '#fff' : '#010606')};
  font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
  outline: none;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ? '#c3eff6' : '#297029')};
  }
  

`;
