import { FaBars } from 'react-icons/fa';
import {
    MobileIcon,
    Nav,
    NavbarContainer,
    NavLogo,
    NavMenu,
    NavItem,
    NavLinks,
    NavBtn,
    NavBtnLink,
    NavLinkTo
} from './NavbarElements';
import { useEffect, useState } from 'react';
import { animateScroll as scroll} from 'react-scroll';
import Logo from '../../images/tax-file-app-logo.png';



const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }

    useEffect(() => {
        return () => {
            window.addEventListener('scroll', changeNav)
        };
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
    <>
          <Nav scrollNav = {scrollNav}>
              <NavbarContainer>
                  <NavLogo to='/' onClick={toggleHome}>
                      <img src={Logo} alt='Tax File App Logo' width='200px'/>
                  </NavLogo>

                  <MobileIcon onClick={toggle}>
                    <FaBars />
                  </MobileIcon>

                  <NavMenu>
                      <NavItem>
                          <NavLinks
                              smooth={true} duration={500} spy={true} exact={true} offset={-60}
                              to='pricing'> Pricing </NavLinks> </NavItem>
                    <NavItem>
                        <NavLinks
                            smooth={true} duration={500} spy={true} exact={true} offset={-60}
                            to='features'> Features </NavLinks> </NavItem>
                    <NavItem>
                        <NavLinks
                            smooth={true} duration={500} spy={true} exact={true} offset={-60}
                            to='forms'> Forms
                        </NavLinks>
                    </NavItem>
                      <NavItem>
                          <NavLinks
                              smooth={true} duration={500} spy={true} exact={true} offset={-60}
                              to='testimonials'> Testimonials
                          </NavLinks>
                      </NavItem>
                      <NavItem>
                          <NavLinks
                              smooth={true} duration={500} spy={true} exact={true} offset={-60}
                              to='contacts'> Contacts </NavLinks> </NavItem>
                    <NavItem>
                        <NavLinkTo id='support'  href='https://support.taxfile.app' target='_blank'> Support </NavLinkTo> </NavItem>
                  </NavMenu>

                    <NavBtn>
                        {/*<NavBtnLink id='download' href='/download'> Download Software</NavBtnLink>*/}
                        <NavBtnLink id='sign-up' href='https://taxfileapp.securetaxfilings.com/register' > Signup / Login</NavBtnLink>
                        {/*<NavBtnLink id='sign-in' href={process.env.REACT_APP_API_BUSINESS_URL} > Sign-in </NavBtnLink>*/}
                    </NavBtn>

                </NavbarContainer>
          </Nav>
      </>
  );
};

export default Navbar;
