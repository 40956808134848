
export const Features = {
    id: 'features',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine1: 'QuickBooks & Xero Integrations',
    headline1: 'QuickBooks and Xero Integrations',
    description1: 'QuickBooks Online and Xero accounting software integrations, offering the users a way to quickly cross-reference their 1099 reports before transmitting their returns directly to the IRS.',
    topLine2: 'TIN Matching & Audit Check',
    headline2: 'TIN Matching & Internal Audit Check',
    topLine3: 'Recipients Copies',
    description2: 'Validates your employee’s TIN against the SSA database and IRS Schema Validation, this will make sure that you are transmitting error-free returns with the Federal and State.',
    headline3: 'Postal Mailing & Address Validation',
    description3: 'Easily provide the recipients with a copy of their tax form with Address validated through our USPS Address Validation to verify that you have entered the right addresses, you can also grant them online access to view and download their form copies, or you can print it and deliver it by hand.',
    topLine4: 'Previous Years Filing',
    headline4: '',
    description4: 'TaxFileApp support filing for the last two years (2022 & 2021) for now, in the feature more years will be supported.',
    topLine5: 'Bulk Upload',
    headline5: '',
    description5: 'Bulk upload templates are the perfect time-saving solution for those filing for multiple EINs or have a large number\n' +
        'of employees. Simplify your filing process and save time with our bulk upload templates.',
    buttonLabel: 'Get Started',
    imgStart: false,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true
}

export const Pricing = {
    id: 'pricing',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Simplified & Transparent Pricing',
    headline1: 'Unlimited Transactions with zero fees',
    description1: 'Get acess to our exclusive app that allows you to send unlimited transactions without getting charged any fees.',
    buttonLabel: 'Get Started',
    imgStart: false,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true
}
export const Testimonials = {
    id: 'testimonials',
    lightBg: false,
    lightText: true,
    lightTextDesc: true
}

// export const Features1 = {
//     id: 'about',
//     lightBg: true,
//     lightText: true,
//     lightTextDesc: false,
//     topLine1: 'Premium Bank',
//     headline1: 'Unlimited Transactions with zero fees',
//     description1: 'Get acess to our exclusive app that allows you to send unlimited transactions without getting charged any fees.',
//     buttonLabel: 'Get Started',
//     imgStart: false,
//     alt: 'Car',
//     dark: true,
//     primary: true,
//     darkText: true
// }

export const homeObjectTwo = {
    id: 'discover',
    lightBg: true,
    lightText: true,
    lightTextDesc: false,
    topLine: 'Premium Bank',
    headline: 'Unlimited Transactions with zero fees',
    description: 'Get acess to our exclusive app that allows you to send unlimited transactions without getting charged any fees.',
    buttonLabel: 'Get Started',
    imgStart: true,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true
}

