import {Bullet, BulletInfo, Container, Header, Paragraph, Title} from '../components/PrivacyTerms';

const Terms = () => {
    return (<Container>
            <Header>Terms of Use and License Agreement for TaxFileApp</Header>
            <Title>Terms</Title>
            <Paragraph> Thank you for selecting TaxFileApp. These terms of use and end-user license agreement (the
                "Agreement") govern your access to and use of the TaxFileApp website at&nbsp;<a target='_blank' href='https://www.taxfile.app' rel="noreferrer">www.TaxFile.app </a>&nbsp; (the
                “Website”), including any content, functionality, and services offered through the Website, and give you
                certain rights and responsibilities depending on the services you purchase or subscribe to through the
                Website as more fully described below (collectively the "Services"). This Agreement is a binding
                contract between you and Tax Form Solutions LLC d/b/a TaxFileApp ("Company," "we," or
                "us"). </Paragraph>

            <Paragraph> BY USING THE SERVICES AND ACCEPTING THESE TERMS, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND
                UNDERSTAND THIS AGREEMENT; (B) REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO
                ENTER INTO THIS AGREEMENT AND, IF ENTERING INTO THIS AGREEMENT FOR AN ORGANIZATION, THAT YOU HAVE THE
                LEGAL AUTHORITY TO BIND THAT ORGANIZATION; AND (C) ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY
                BOUND BY ITS TERMS AND THE TERMS OF OUR PRIVACY POLICY FOUND AT
                WWW.taxfile.app/privacy-policy. </Paragraph>

            <Paragraph> If you do not agree to the terms of this Agreement and our Privacy Policy, you are not granted
                any rights whatsoever to use the Website or access the Services, and you will not be able to access or
                use the Services or the Website. </Paragraph>

            <Paragraph> The Services and the Website are offered to users who are 18 years of age or older and reside in
                the United States or any of its territories. If you do not meet all of these requirements, you must not
                access or use the Website or the Services. </Paragraph>

            <Title>1. Changes to this Agreement</Title>
            <Paragraph> We may revise and update this Agreement from time to time in our sole discretion. All changes
                are effective immediately when we post them and apply to all access to and use of the Website and the
                Services
                thereafter.
                Your continued use of the Website or the Services following the posting of a revised version of this
                Agreement means that you accept and agree to the changes. You are expected to check this page from time
                to time so you are aware of any changes, as they are binding on you. </Paragraph>

            <Title>2. Description of Services</Title>
            <Paragraph> We make various services available on the Website including, but not limited to, cloud-based tax
                information reporting and e-filing services (the “Services”). In addition to the TaxFileApp
                software,
                the term
                "Services" includes any other programs, tools, internet-based services, components and any "updates"
                (for example, software maintenance, service information, help content, bug fixes, or maintenance
                releases
                etc.) of the TaxFileApp software that Tax Form Solutions LLC provides or makes available to you as
                part
                of the TaxFileApp product series. </Paragraph>

            <Paragraph> Fees for the various services are set out in the service fees described elsewhere on the
                Website. You are solely responsible for providing, at your own expense, all equipment necessary to use
                the
                Website and
                the Services, including a computer and modem; and your own Internet access.</Paragraph>

            <Title>3. Changes to the Website and the Services</Title>
            <Paragraph> We may update the content on this Website from time to time, but its content is not necessarily
                complete or up-to-date. Any of the material on the Website may be out of date at any given time, and we
                are
                under no
                obligation to update such material. </Paragraph>

            <Paragraph> We reserve the sole right to either modify or discontinue the Website or the Services, including
                any features therein, at any time with or without notice to you. We shall not be liable to you or any
                third
                party should we exercise such right. Modifications may include, but are not limited to, changes in the
                pricing structure, the addition of fee-based services, or changes to limitations on allowable file
                sizes. Any new features that augment or enhance the then-current services on this site shall also be
                subject
                to
                this Agreement. </Paragraph>

            <Paragraph> You understand and agree that temporary interruptions of the services available through this
                site may occur as normal events. You further understand and agree that we have has no control over third
                party networks
                you may access in the course of the use of this site, and therefore, delays and disruption of other
                network
                transmissions are completely beyond our control. </Paragraph>

            <Title>4. TaxFileApp Cloud Services Access and Use</Title>
            <Paragraph>Subject to and conditioned on compliance with all other terms and conditions of this Agreement,
                Company hereby grants you a revocable, non-exclusive, non-transferable, non-sublicensable right to
                access
                and
                use the Services for which you have paid all applicable fees. User IDs and passwords shall be required
                to
                access and use the Services, and access will only be granted after you have registered for an
                account.</Paragraph>

            <Paragraph>If you are a tax preparer qualified by the Internal Revenue Service (a “Tax Preparer”), you may
                use the Services on behalf of your clients on a professional or commercial basis. All other users of the
                Services may use the Services only on behalf of themselves or for internal business purposes on behalf
                of a
                business for which they are authorized to act and may not use the Services on a professional or
                commercial
                basis.</Paragraph>

            <Title>5. Use Restrictions</Title>
            <Paragraph>You shall not use the Website or the Services for any purposes beyond the scope of the access
                granted in this Agreement. You shall not at any time, directly or indirectly use the Services or the
                Website:</Paragraph>

            <Bullet> <BulletInfo> In any way that violates any applicable federal, state, local, or international law or
                regulation.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> To attempt to access any Tax Form SOlutions LLC systems, programs or data that are not
                made available for
                public use.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> To copy, modify, or create derivative works of the Services, or any software component
                of the Cloud
                Services, in whole or in part.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> To reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to
                derive or gain access
                to any software component of the Services, in whole or in part.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> To bypass or breach any security device or protection used by the Services or access
                or use the Services other than through the use of your own access credentials.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> To use the Services for any commercial purpose, including to prepare tax returns,
                schedules or worksheets on a professional or commercial basis (i.e., for a preparer's or other fee),
                unless you are a Tax
                Preparer.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> To impersonate or attempt to impersonate the Company, a Company employee, another
                user, or any other person or entity (including, without limitation, by using email addresses or User ID
                associated with any
                of the foregoing).</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> To perform or attempt to perform any actions that would interfere with the proper
                working of the Website or the Services, prevent access to or the use of the Website or the Services by
                Tax Form Solutions LLC's
                other licensees or customers, or impose an unreasonable or disproportionately large load on the
                Company’s infrastructure.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Use any robot, spider, or other automatic device, process, or means to access the
                Website for any purpose, including monitoring or copying any of the material on the
                Website.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Use any manual process to monitor or copy any of the material on the Website, or for
                any other purpose not expressly authorized in these Terms of Use, without our prior written
                consent.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Use any device, software, or routine that interferes with the proper working of the
                Website.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is
                malicious or
                technologically harmful.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts
                of the Website, the
                server on which the Website is stored, or any server, computer, or database connected to the
                Website.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Attack the Website via a denial-of-service attack or a distributed denial-of-service
                attack.</BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Otherwise attempt to interfere with the proper working of the Website.</BulletInfo>
            </Bullet>
            <Bullet> <BulletInfo> Otherwise use the Services except as expressly allowed under this Section
                4.</BulletInfo> </Bullet>
            <Paragraph>If you obtained a license for the free version of the TaxFileApp software, there may be
                additional
                fees and limitations on your use of the features and functionality, as stated within the
                TaxFileApp
                software.</Paragraph>

            <Title>6. Reservation of Rights and Ownership</Title>
            <Paragraph>The Services are licensed not sold, and Tax Form Solutions LLC reserves all rights not expressly
                granted to
                you in this Agreement. The software components of the Services are protected by copyright, trade secret
                and
                other intellectual property laws.Tax Form Solutions LLC and its licensors own the title, copyright, and
                other intellectual property rights in the software components of the Services. The Company name, the
                Company
                logo, and all related names, logos, product and service names, designs, and slogans are trademarks of
                the
                Company or its affiliates or licensors. You must not use such marks without the prior written permission
                of
                the Company. All other names, logos, product and service names, designs, and slogans on this Website are
                the
                trademarks of their respective owners.</Paragraph>

            <Title>7. Customer Responsibilities</Title>
            <Paragraph>You are responsible for verifying the filing status of each return or filing TaxFileApp
                files on your
                behalf and for promptly correcting any errors in information.</Paragraph>

            <Title>8. TaxFileApp Accurate Calculations Guarantee</Title>
            <Paragraph>Tax Form Solutions LLC works diligently to ensure the accuracy of the calculations on every form
                prepared
                using the Services.</Paragraph>

            <Paragraph>If you are a registered user and you pay an IRS or state penalty and/or interest solely because
                of a
                calculation error on a form prepared for you using TaxFileApp, and not as a result of, among other
                things, your failure to enter all required information accurately, willful or fraudulent omission or
                inclusion of information on your tax return, misclassification of information on the tax return, or
                failure
                to file an amended return to avoid or reduce an applicable penalty/interest after Tax Form Solutions LLC
                announced updates or corrections to the TaxFileApp software in time for you to file an amended
                return,
                then Tax Form Solutions LLC will pay you in the amount of the IRS or state penalty and/or interest paid
                by
                you to the IRS or state. In this regard, you are responsible for keeping Tax Form Solutions LLC apprised
                promptly of any change in your email address, mailing address and/or phone number so that you can be
                notified of such updates or corrections. You are responsible for paying any additional tax liability you
                may
                owe and providing any other information Tax Form Solutions LLC reasonably requests. A "registered user"
                is a
                user from whom Tax Form Solutions LLC has received the information necessary to permit such person to
                print
                or electronically file a tax return prepared using the TaxFileApp software and who complies with
                the
                terms and conditions of this Agreement.</Paragraph>

            <Paragraph>If you believe such a calculation error occurred, you must notify Tax Form Solutions as soon as
                you learn of
                the mistake (and in no event later than 30 days after the penalty or interest is assessed) at
                http://www.taxfile.app/contact-us/, or phone at (888) 479-0979 or email support@taxfile.app.
                Tax
                Form Solutions LLC will then contact you promptly to resolve the issue. to resolve your matter, Tax Form
                Solutions LLC may require your TaxFileApp data file and other supporting information such as a
                copy of
                the irs state notice, evidence of payment of the specified penalty and/or interest, and a copy of your
                printed tax return.</Paragraph>

            <Title>9. Use with Third-Party Products and Services</Title>
            Third-party service providers (“Third-Party Providers”) may integrate their services with TaxFileApp
            such that data from the third party’s environment will be transferred into your TaxFileApp account (an
            “Online Data Transfer”). All Online Data Transfers are one-way transfers, meaning that information entered
            into the TaxFileApp system will be available only in TaxFileApp and it's partners and will not
            transfer back to the third party’s environment. You understand that any changes to data made within
            TaxFileApp will not be visible in any other application unless you also update the data within that
            application. You are solely responsible for updating data in any system other than TaxFileApp. You
            understand that TaxFileApp has no control over any Third-Party Providers or their products or
            services, and that your use of any services they provide is subject to the Third-Party Providers’ terms of
            service.

            <Title>10. Account Registration and Security</Title>
            You are responsible for: (A) making all arrangements necessary for you to access the Website or the Services
            and (B) ensuring that all persons who access the Website or the Services through your internet connection
            are aware of this Agreement and comply with it.

            <Paragraph>To access portions of the Website and the Services, you will be required to provide certain
                registration
                details or other information. It is a condition of your use of the Website and the Services that all the
                information you provide during the account registration process is correct, current, and complete. By
                registering, you agree that all information provided in the registration data is true and accurate and
                that
                you will maintain and update this information as required in order to keep it current, complete and
                accurate. You agree that all information you provide to register with this Website or otherwise,
                including,
                but not limited to, through the use of any interactive features on the Website, is governed by our
                Privacy
                Policy, and you consent to all actions we take with respect to your information consistent with our
                Privacy
                Policy.</Paragraph>

            <Paragraph>If you are provided with, a user ID, password, or any other piece of information as part of our
                security
                procedures, you must treat such information as confidential, and you must not disclose it to any other
                person or entity, except if you choose to provide that information to Tax Form Solutions LLC's
                authorized
                technical support personnel to assist you. You also acknowledge that your account is personal to you and
                agree not to provide any other person with access to this Website or the Services using your user ID,
                password, or other security information. You agree to notify us immediately of any unauthorized access
                to or
                use of your user ID or password or any other breach of security. You also agree to ensure that you exit
                from
                your account at the end of each session. You should use particular caution when accessing your account
                from
                a public or shared computer so that others are not able to view or record your password or other
                personal
                information.</Paragraph>

            <Paragraph>We have the right to disable any user ID, password, or other identifier, whether chosen by you or
                provided
                by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have
                violated any provision of this Agreement.</Paragraph>

            <Paragraph>You are responsible for the use of the Services and the Website under your user ID.Tax Form
                Solutions LLC
                will not disclose your password if you lose or forget it. If you have not electronically filed or
                printed
                your tax filing, you must create a user ID and password in order for you to access your data at a later
                date. You must remember your user ID and password to electronically transfer your information into later
                filings if you choose to do so. You should confirm that the pricing for your use of the Services has not
                changed, particularly if some time has passed between the date you start your tax filing and the date
                you
                finish and are ready to file or print and pay for it.</Paragraph>

            <Title>11. Payment of Fees</Title>
            <Paragraph>If you sign up for Services that require payment of a fee, you agree to pay all fees associated
                with such
                Services. For all charges for Services, we will bill your credit card. All charges are billed in advance
                of
                service. You agree to provide us with accurate and complete billing information, including valid credit
                card
                information, your name, address and telephone number. You agree to update your account information
                promptly
                with any changes that may occur, including changes either in your billing address or your credit card
                expiration date. All charges are final and non-refundable.</Paragraph>

            <Paragraph>If, for any reason, your credit card company refuses to pay the amount billed for the service,
                you agreement
                that we may, at our option, suspend or terminate your access to the Services and require you to pay the
                overdue amount by other means acceptable to us. We may charge a fee for reinstatement of suspended or
                terminated accounts.</Paragraph>

            <Paragraph>In the event legal action is necessary to collect on balances due, you agree to reimburse us for
                all
                expenses incurred to recover sums due, including attorney fees and other legal expenses.</Paragraph>

            <Title>12. Intellectual Property Rights in Website</Title>
            <Paragraph>The Website and its entire contents, features, and functionality (including but not limited to
                all
                information, software, text, displays, images, video, and audio, and the design, selection, and
                arrangement
                thereof) are owned by the Company, its licensors, or other providers of such material and are protected
                by
                United States and international copyright, trademark, patent, trade secret, and other intellectual
                property
                or proprietary rights laws.</Paragraph>

            <Paragraph>Unless you are a Tax Preparer, this Agreement permits you to use the Website for non-commercial,
                personal or
                internal business use only. You must not reproduce, distribute, modify, create derivative works of,
                publicly
                display, publicly perform, republish, download, store, or transmit any of the material on our Website,
                except as follows:</Paragraph>

            <Bullet> <BulletInfo> Your computer may temporarily store copies of such materials in RAM incidental to your
                accessing and
                viewing those materials. </BulletInfo></Bullet>
            You may store files that are automatically cached by your Web browser for display enhancement purposes.
            <Bullet> <BulletInfo> You may print or download one copy of a reasonable number of pages of the Website for
                your own personal,
                non-commercial use and not for further reproduction, publication, or distribution.</BulletInfo></Bullet>
            <Bullet> <BulletInfo> If we provide desktop, mobile, or other applications for download, you may download a
                single copy to your
                computer or mobile device solely for your own personal or internal business, non-commercial use,
                provided
                you agree to be bound by our end user license agreement for such applications.</BulletInfo></Bullet>
            <Bullet> <BulletInfo> If we provide social media features that allow you to share certain content, you may
                take such actions as
                are enabled by such features.</BulletInfo></Bullet>

            <Paragraph>You must not:</Paragraph>

            <Bullet> <BulletInfo> Modify copies of any materials from this site.</BulletInfo></Bullet>
            <Bullet> <BulletInfo> Use any illustrations, photographs, video or audio sequences, or any graphics
                separately from the
                accompanying text.</BulletInfo></Bullet>
            <Bullet> <BulletInfo> Delete or alter any copyright, trademark, or other proprietary rights notices from
                copies of materials
                from this site.</BulletInfo></Bullet>
            <Bullet> <BulletInfo> If you print, copy, modify, download, or otherwise use or provide any other person
                with access to any part
                of the Website in breach of this Agreement, your right to use the Website will stop immediately and you
                must, at our option, return or destroy any copies of the materials you have made. No right, title, or
                interest in or to the Website or any content on the Website is transferred to you, and all rights not
                expressly granted are reserved by the Company. Any use of the Website not expressly permitted by this
                Agreement is a breach of these Terms of Use and may violate copyright, trademark, and other
                laws.</BulletInfo></Bullet>

            <Title>13. Privacy of Personal and Tax Information</Title>
            <Paragraph>At Tax Form Solutions LLC we place the highest importance on respecting and protecting the
                privacy of our
                customers. Our most important asset is our relationship with you. We want you to feel comfortable and
                confident when using our products and services and with entrusting your tax information to us. All
                information we collect on this Website and through your use of the Services is subject to our Privacy
                Policy. By using the Website or the Services you consent to all actions taken by us with respect to your
                information in compliance with the Privacy Policy.</Paragraph>

            <Paragraph>If you are a California resident, California law may provide you with additional rights regarding
                our
                collection and use of your Personal Information. To learn more about your California privacy rights,
                visit
                the Privacy Notice for California Residents.</Paragraph>

            <Title>14. Customer Data</Title>
            <Paragraph>"Customer Data" means all information, data, and other content, in any form or medium, that is
                submitted,
                posted, or otherwise transmitted through the Website or the Services by or on behalf of you.</Paragraph>

            <Paragraph>All Customer Data remains your property, however, you hereby grant Tax Form Solutions LLC a
                non-exclusive,
                royalty-free, worldwide license to reproduce, distribute, and otherwise use and display the Customer
                Data
                and perform all acts with respect to the Customer Data as may be necessary for us to provide the
                Services to
                you. Customer will ensure that Customer Data will not violate any policy or terms referenced in or
                incorporated into this Agreement or any applicable law. Customer is solely responsible for the
                development,
                content, operation, maintenance, and use of Customer Data.</Paragraph>

            <Paragraph>The Services do not replace the need for you to maintain regular data backups or redundant data
                archives.
                PROVIDER HAS NO OBLIGATION OR LIABILITY FOR ANY LOSS, ALTERATION, DESTRUCTION, DAMAGE, CORRUPTION, OR
                RECOVERY OF CUSTOMER DATA.</Paragraph>


            <Title>15. Anonymized Statistical Data</Title>
            <Paragraph>When you use the Services or visit the Website, we may create anonymized statistical data from
                your data and
                usage of our services, including through aggregation. Once anonymized, we may use it for our own
                purposes,
                such as to provide and improve our services, to develop new services or product offerings, to identify
                business trends, and for other uses we communicate to you.</Paragraph>

            <Title>16. Linking to the Website and Social Media Features</Title>
            <Paragraph>You may link to our homepage, provided you do so in a way that is fair and legal and does not
                damage our
                reputation or take advantage of it, but you must not establish a link in such a way as to suggest any
                form
                of association, approval, or endorsement on our part without our express written consent.</Paragraph>

            <Paragraph>This Website may provide certain social media features that enable you to:</Paragraph>

            <Paragraph>Link from your own or certain third-party websites to certain content on this Website.
                Send emails or other communications with certain content, or links to certain content, on this Website.
                Cause limited portions of content on this Website to be displayed or appear to be displayed on your own
                or
                certain third-party websites.
                You may use these features solely as they are provided by us and solely with respect to the content they
                are
                displayed with, and otherwise in accordance with any additional terms and conditions we provide with
                respect
                to such features. Subject to the foregoing, you must not:</Paragraph>

            <Paragraph>Establish a link from any website that is not owned by you.
                Cause the Website or portions of it to be displayed on, or appear to be displayed by, any other site,
                for
                example, framing, deep linking, or in-line linking.
                Otherwise take any action with respect to the materials on this Website that is inconsistent with any
                other
                provision of this Agreements</Paragraph>

            <Paragraph>You agree to cooperate with us in causing any unauthorized framing or linking immediately to
                stop. We
                reserve the right to withdraw linking permission without notice. We may disable all or any social media
                features and any links at any time without notice in our discretion.</Paragraph>

            <Title>17. Links from the Website</Title>
            <Paragraph>If the Website contains links to other sites and resources provided by third parties, these links
                are
                provided for your convenience only. We have no control over the contents of those sites or resources and
                accept no responsibility for them or for any loss or damage that may arise from your use of them. If you
                decide to access any of the third-party websites linked to this Website, you do so entirely at your own
                risk
                and subject to the terms and conditions of use for such websites.</Paragraph>

            <Title>18. No Professional Services</Title>
            <Paragraph>Tax Form Solutions LLC is not a tax or accounting firm and does not provide any tax, accounting,
                financial,
                legal, or other professional services or advice. Although various financially or legally significant
                forms
                are available through the Website, the delivery and use of these forms does not constitute accounting or
                other professional advice. Under no circumstances will the Company be liable for any loss or damages
                caused
                by your reliance on information or advice obtained through the Website, including your use of the
                Services
                or of any forms. It is your responsibility to evaluate the accuracy, completeness, usefulness, or
                appropriateness of any information, opinions, advice, forms or other content available through the
                Website
                in light of your particular situation. You are urged to consult an appropriate professional licensed in
                your
                jurisdiction before using any forms, or when you need legal, accounting, or other professional
                advice.</Paragraph>

            <Title>19. Disclaimer of Warranties</Title>
            <Paragraph>You understand that we cannot and do not guarantee or warrant that files available for
                downloading from the
                internet or the Website will be free of viruses or other destructive code. You are responsible for
                implementing sufficient procedures and checkpoints to satisfy your particular requirements for
                anti-virus
                protection and accuracy of data input and output, and for maintaining a means external to our site for
                any
                reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
                LOSS
                OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL
                MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL
                DUE
                TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING
                OF
                ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</Paragraph>

            <Paragraph>YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT
                YOUR OWN
                RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN
                "AS
                IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE
                COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO
                THE
                COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE, OR ANY SERVICES
                OR
                ITEMS OBTAINED THROUGH THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
                ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR
                ITEMS
                OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL
                BE
                CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS, OR THAT THE WEBSITE, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE
                MEET
                YOUR NEEDS OR EXPECTATIONS.</Paragraph>

            <Paragraph>TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
                WHETHER
                EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                MERCHANTABILITY,
                NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</Paragraph>

            <Paragraph>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
                LAW. SOME
                STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
                IN
                THAT EVENT, ANY IMPLIED WARRANTIES ARE LIMITED IN DURATION TO 60 DAYS FROM THE DATE OF PURCHASE OF
                SERVICES.
                HOWEVER, SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
                LIMITATION
                MAY NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY HAVE OTHER RIGHTS THAT
                VARY
                FROM STATE TO STATE.</Paragraph>

            <Paragraph>All warranties or guarantees given or made by Tax Form Solutions LLC with respect to the Services
                (1) are
                solely for the benefit of you as a registered user of the Services and are not transferable, and (2)
                shall
                be null and void if you breach any term or condition of this Agreement.</Paragraph>

            <Title>20. Limitation of Liability and Damages</Title>
            <Paragraph>YOU UNDERSTAND THAT TAX FORM SOLUTIONS LLC WILL NOT AUDIT OR OTHERWISE VERIFY ANY INFORMATION YOU
                PROVIDE
                AND IS NOT RESPONSIBLE FOR DISALLOWED DEDUCTIONS OR CREDITS. FURTHER, TAX FORM SOLUTIONS LLC SHALL NOT
                BE
                RESPONSIBLE FOR ADDITIONAL TAXES, PENALTIES AND INTEREST THAT ARE ASSESSED AS THE RESULT OF INCORRECT,
                INCOMPLETE OR MISLEADING INFORMATION THAT YOU HAVE GIVEN TO TAX FORM SOLUTIONS LLC IN CONNECTION WITH
                THE
                SERVICES.</Paragraph>

            <Paragraph>EXCEPT FOR THE REIMBURSEMENT FOR CALCULATION ERRORS DESCRIBED IN SECTION 7, THE ENTIRE CUMULATIVE
                LIABILITY
                OF TAX FORM SOLUTIONS LLC AND ITS SUPPLIERS FOR ANY REASON ARISING FROM OR RELATING TO THIS AGREEMENT
                SHALL
                BE LIMITED TO THE AMOUNT PAID BY YOU FOR THE SERVICES, AS APPLICABLE, TO TAX FORM SOLUTIONS LLC OR ITS
                AUTHORIZED RESELLER.</Paragraph>

            <Paragraph>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, TAX FORM SOLUTIONS LLC AND ITS SUPPLIERS ARE
                NOT LIABLE
                FOR ANY INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR FOR DAMAGES RELATING TO
                LOSS OF
                BUSINESS, TELECOMMUNICATION FAILURES, LOSS, CORRUPTION, SECURITY OR THEFT OF DATA, VIRUSES, SPYWARE,
                LOSS OF
                PROFITS OR INVESTMENT, TAX POSITIONS TAKEN BY YOU, INABILITY TO MAKE A TAX FILING, DELAY IN PREPARING
                ANY
                TAX FILING, INCORRECT OR INCOMPLETE INFORMATION PROVIDED TO TAX FORM SOLUTIONS LLC, ANY ACCESS TO, OR
                USE
                OF, YOUR PASSWORD AND USER ID BY AN UNAUTHORIZED PERSON, OR THE LIKE, WHETHER BASED IN CONTRACT, TORT
                (INCLUDING NEGLIGENCE), STRICT LIABILITY, PRODUCT LIABILITY OR OTHERWISE, EVEN IF TAX FORM SOLUTIONS LLC
                OR
                ITS SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF A REMEDY SET FORTH HEREIN
                IS
                FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. IN NO EVENT WILL TAX FORM SOLUTIONS LLC BE LIABLE FOR ANY
                LOSS, COST, LIABILITY OR DAMAGE INCURRED AS A RESULT OF YOUR RECEIPT OF OR PARTICIPATION IN THIRD PARTY
                SERVICES OR THIRD PARTY WEBSITES. IN NO EVENT DOES TAX FORM SOLUTIONS LLC ASSUME ANY LIABILITY TO ANY
                PARTY
                OTHER THAN YOU ARISING OUT OF YOUR USE OR INABILITY TO USE THE SERVICES. THE LIMITATIONS OF DAMAGES SET
                FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN TAX FORM SOLUTIONS LLC AND YOU.
                TAX
                FORM SOLUTIONS LLC WOULD NOT BE ABLE TO HAVE PROVIDED THE SERVICES WITHOUT SUCH LIMITATIONS.</Paragraph>

            <Title>21. Indemnification</Title>
            <Paragraph>You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and
                service
                providers, and its and their respective officers, directors, employees, contractors, agents, licensors,
                suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards,
                losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to
                your
                violation of this Agreement or your use of the Website or the Services, including, but not limited to,
                any
                use of the Website's content, services, and products other than as expressly authorized in this
                Agreement,
                or your use of any information obtained from the Website.</Paragraph>

            <Paragraph>You must indemnify and defend Tax Form Solutions LLC against any claims or lawsuits, including
                attorneys'
                fees that arise from or result from the use of the Services on a professional or commercial
                basis.</Paragraph>

            <Title>22. No Third-Party Beneficiaries</Title>
            <Paragraph>The Website and the Services are provided for your sole benefit. We have no responsibility to any
                person
                other than you, and nothing in this Agreement confers a benefit on any person other than
                you.</Paragraph>

            <Title>23. Governing Law and Jurisdiction</Title>
            <Paragraph>All matters relating to the Website, the Services, and this Agreement, and any dispute or claim
                arising
                therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be
                governed
                by and construed in accordance with the internal laws of the State of Kentucky without giving effect to
                any
                choice or conflict of law provision or rule (whether of the State of Kentucky or any other
                jurisdiction).</Paragraph>

            <Paragraph>Any legal suit, action, or proceeding arising out of, or related to the Website, the Services,
                and this
                Agreement, shall be instituted exclusively in the federal courts of the United States or the courts of
                the
                State of Kentucky. You waive any and all objections to the exercise of jurisdiction over you by such
                courts
                and to venue in such courts.</Paragraph>

            <Title>24. Arbitration</Title>
            <Paragraph>At Company's sole discretion, it may require You to submit any disputes arising from these this
                Agreement or
                use of the Website, or the Services, including disputes arising from or concerning the interpretation,
                violation, invalidity, non-performance, or termination of this Agreement, to final and binding
                arbitration
                under the Rules of Arbitration of the American Arbitration Association applying Kentucky
                law.</Paragraph>

            <Title>25. Limitation on Time to File Claims</Title>
            <Paragraph>NO CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT, THE
                WEBSITE, OR THE
                SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE
                OF
                ACTION OR CLAIM IS PERMANENTLY BARRED.</Paragraph>

            <Title>26. Consent to Conduct Business Electronically ("Consent")</Title>
            <Paragraph>Consent to Electronic Communications. Tax Form Solutions LLC may be required by law to send
                "Communications" by SMS, email, or call
                to you that may pertain to the use of information you may submit to Tax Form Solutions LLC and the
                Services
                you choose. Additionally, certain of the Third Party Services you choose, may require Communications
                with
                the third parties who administer these programs. You agree that Tax Form Solutions LLC, on behalf of
                itself
                and others who administer such Services (as applicable), may send Communications to you by email and/or
                may
                make Communications available to you by posting them at one or more of our sponsored websites, such as
                https://www.taxfile.app you consent to receive these Communications electronically. The term
                "Communications" means any notice, record, agreement, or other type of information that is made
                available to
                you or received from you in connection with the Services.</Paragraph>

            <Paragraph>Consenting to Do Business Electronically. The decision whether to do business electronically is
                yours, and
                you should consider whether you have the required hardware and software capabilities described below.
                Your
                consent to do business electronically, and our agreement to do so, applies to this Agreement and any
                applicable Services.</Paragraph>

            <Paragraph>Hardware and Software Requirements. In order to access and retain an electronic record of
                Communications,
                you will need: a computer, a monitor, a connection to an Internet service provider, Internet browser
                software that supports 128-bit encryption, and an e-mail address. By selecting the "I accept and agree
                to
                the terms of the License Agreement" button; you are confirming to us that you have the means to access,
                and
                to print or download, Communications. We do not provide ISP services. You must have your own Internet
                service provider.</Paragraph>

            <Paragraph>Withdrawal of Consent. If you later decide that you do not want to receive future Communications
                electronically, write to us at TaxFileApp Customer Service, support@taxfile.app. If you
                withdraw
                your consent to receive Communications electronically, we may terminate your use of Tax Advice and one
                or
                more of the Services.
                Changes to Your Email Address. You agree to notify us promptly of any change in your email address. You
                can
                do so by logging on to the application and following the instructions to submit a comment to Tax Form
                Solutions LLC (please include both your old and new email address).</Paragraph>

            <Title>27. Waiver and Severability</Title>
            <Paragraph>No waiver by the Company of any term or condition set out in this Agreement shall be deemed a
                further or
                continuing waiver of such term or condition or a waiver of any other term or condition, and any failure
                of
                the Company to assert a right or provision under this Agreement shall not constitute a waiver of such
                right
                or provision.</Paragraph>

            <Paragraph>If any provision of this Agreement is held by a court or other tribunal of competent jurisdiction
                to be
                invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the
                minimum extent such that the remaining provisions of the Agreement will continue in full force and
                effect.</Paragraph>

            <Paragraph>Any waiver of the terms herein by Tax Form Solutions must be in a writing signed by an authorized
                officer of
                Tax Form Solutions LLC and expressly referencing the applicable provisions of this
                Agreement.</Paragraph>

            <Title>28. Force Majeure</Title>
            <Paragraph>In no event shallTax Form Solutions LLC be liable to you, or be deemed to have breached this
                Agreement, for
                any failure or delay in performing its obligations under this Agreement, if and to the extent such
                failure
                or delay is caused by any circumstances beyond Provider's reasonable control, including but not limited
                to
                acts of God, flood, fire, earthquake, pandemics, epidemics, explosion, war, terrorism, invasion, riot or
                other civil unrest, strikes, labor stoppages or slowdowns or other industrial disturbances, or passage
                of
                law or any action taken by a governmental or public authority, including imposing an
                embargo.</Paragraph>

            <Title>29. Miscellaneous</Title>
            <Paragraph>In any action to enforce this Agreement, the prevailing party will be entitled to costs and
                attorneys' fees.</Paragraph>

            <Paragraph>You may not assign your rights and obligations under this Agreement to any other party, and any
                purported
                attempt to do so shall be null and void. We may freely assign our rights and obligations under this
                Agreement.</Paragraph>

            <Title>30. Termination</Title>
            <Paragraph>Tax Form Solutions LLC may terminate your rights under this Agreement immediately and without
                notice if you
                fail to comply with any term or condition of this Agreement or no longer consent to electronic
                communications. Upon such termination, you must immediately cease using the Services. Any termination of
                this Agreement shall not affect Tax Form Solutions LLC's rights hereunder.</Paragraph>

            <Title>31. Questions</Title>
            <Paragraph>You can contact Tax Form Solutions LLC, Inc. by calling +1 (888) 479-0979 or by email &nbsp;
                <a href='mailto:support@taxfile.app'>support@taxfile.app</a> &nbsp;
                if you have a question or concern about any product or service we sell</Paragraph>
        </Container>


    );
};

export default Terms;