import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SideBtnWrap,
    SidebarLink,
    SidebarRout,
    SidebarMenu,
    SidebarLinkTo
} from './SidebarElements';

const Sidebar = ({ isOpen, toggle }) => {

  return (
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
          <Icon onClick={toggle}>
              <CloseIcon/>
          </Icon>

      <SidebarWrapper>
      <SidebarMenu>
          <SidebarLink to='pricing' onClick={toggle}> Pricing </SidebarLink>
          <SidebarLink to='features' onClick={toggle}> Features </SidebarLink>
          <SidebarLink to='forms' onClick={toggle}> Forms </SidebarLink>
          <SidebarLink to='contacts' onClick={toggle}> Contacts </SidebarLink>
          <SidebarLinkTo href='https://support.taxfile.app' onClick={toggle}> Support </SidebarLinkTo>
    </SidebarMenu>
    <SideBtnWrap>
        <SidebarLinkTo href='https://taxfileapp.securetaxfilings.com/login'> Sign in </SidebarLinkTo>
    </SideBtnWrap>
        </SidebarWrapper>



      </SidebarContainer>
  );
};

export default Sidebar;
