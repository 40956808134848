import React from 'react';
import {Container, Paragraph} from "../components/PrivacyTerms";

const CPPA = () => {
    return (
        <Container>
            <Paragraph>The California Consumer Privacy Act (CCPA) provides California residents with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</Paragraph>
            <div class="terms-box-inner">
                <h4>Information Collected</h4>
                <Paragraph class="inner-top-caption">We do not sell or rent your personal information to anyone for promotional or marketing purposes. However we may collect personal information about you from the following categories and sources to complete your IRS return(s):</Paragraph>
                <ul class="separate_list">
                    <li>Information provided when you create an account or request services or information from us, such as name, address, email address, phone number, and payment information. We request your phone number to communicate the status of your return filed with the IRS through a text message.</li>
                    <li>Information provided through user feedback, community discussions, chats, and other interactions, such as surveys, through our sites.</li>
                    <li>
                        Information provided when administering technical or customer support for you to prepare your tax return or for other services.
                    </li>
                    <li>Information provided by your system as you interact with our site(s), such as your IP address and browser information.</li>
                    <li>Information provided by you when you upload a form or other file, from your desktop or mobile device, we collect and store those files and/or data securely.</li>
                    <li>If you decide to pay for our services, we may request additional information, including payment information. If you pay using a credit card, though we will collect financial information such as credit card number and expiration date, we do not store the credit card information at our end. Instead, we use the services provided by the authorized payment gateway to authenticate your identity and approve the payment.</li>
                </ul>
                <Paragraph class="inner-top-caption">We may also collect:</Paragraph>
                <ul class="separate_list">
                    <li>Information from your use of our referral service to tell a friend about our site, we will ask you for your friend's name and email address.</li>
                    <li>Information from publicly available sources or third parties, such as when we partner with other companies or use service providers to help us optimize our marketing activities.</li>
                </ul>
                <Paragraph class="inner-top-caption">If you have used our Services to prepare a tax return, we have collected the following categories of Personal Information from you within the last twelve (12) months:</Paragraph>
                <div class="custom-table mTop20 table-responsive">
                    <table>
                        <tbody>
                        <tr>
                            <th>Categories</th>
                            <th>Examples</th>
                            <th>Purpose</th>
                            <th>Collected</th>
                        </tr>
                        <tr>
                            <td>Identifiers.</td>
                            <td>A real name, Internet Protocol address, email address, or other similar identifiers.</td>
                            <td>
                                <Paragraph>TaxFileApp uses this information only in accordance with applicable laws, such as to prepare, assist in preparing, or obtain or provide services in connection with preparing your tax return; to provide you with the products and services you specifically request or consent to and other uses or disclosures as you expressly consent to from time to time; or as required by law..</Paragraph>
                                <Paragraph>The use and disclosure of Tax Return Information is governed by Section 301-7216 of the Internal Revenue Code and many state codes and regulations.</Paragraph>
                            </td>
                            <td>
                                Yes
                            </td>
                        </tr>
                        <tr>
                            <td>Personal Information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</td>
                            <td>
                                <Paragraph>A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information.</Paragraph>
                                <Paragraph>Some personal information included in this category may overlap with other categories.</Paragraph>
                            </td>
                            <td>
                                <Paragraph>TaxFileApp uses this information only in accordance with applicable laws, such as to prepare, assist in preparing, or obtain or provide services in connection with preparing your tax return; to provide you with the products and services you specifically request or consent to and other uses or disclosures as you expressly consent to from time to time; or as required by law.</Paragraph>
                                <Paragraph>The use and disclosure of Tax Return Information is governed by Section 301-7216 of the Internal Revenue Code and many state codes and regulations.</Paragraph>
                            </td>
                            <td>
                                Yes
                            </td>
                        </tr>
                        <tr>
                            <td>Protected classification characteristics under California or federal law.</td>
                            <td>
                                <Paragraph>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</Paragraph>
                            </td>
                            <td>
                                <Paragraph>TaxFileApp uses this information only in accordance with applicable laws, such as to prepare, assist in preparing, or obtain or provide services in connection with preparing your tax return; to provide you with the products and services you specifically request or consent to and other uses or disclosures as you expressly consent to from time to time; or as required by law.</Paragraph>
                                <Paragraph>The use and disclosure of Tax Return Information is governed by Section 301-7216 of the Internal Revenue Code and many state codes and regulations.</Paragraph>
                            </td>
                            <td>
                                Yes
                            </td>
                        </tr>
                        <tr>
                            <td>Commercial information.</td>
                            <td>
                                <Paragraph>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</Paragraph>
                            </td>
                            <td>
                                <Paragraph>TaxFileApp does not collect this category of information.</Paragraph>
                            </td>
                            <td>
                                No
                            </td>
                        </tr>
                        <tr>
                            <td>Biometric information.</td>
                            <td>
                                <Paragraph>Genetic, physiological, behavioral, and biological characteristics or activity patterns used to extract a template or other identifier or identifying information, such as fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</Paragraph>
                            </td>
                            <td>
                                <Paragraph>TaxFileApp does not collect this category of information.</Paragraph>
                            </td>
                            <td>
                                No
                            </td>
                        </tr>
                        <tr>
                            <td>Internet or other similar network activity.</td>
                            <td>
                                <Paragraph>Browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement.</Paragraph>
                            </td>
                            <td>
                                <Paragraph>TaxFileApp uses your information to personalize or customize your experience and our products, develop new features or services, and to improve the overall quality of our product offerings.</Paragraph>
                            </td>
                            <td>
                                Yes
                            </td>
                        </tr>
                        <tr>
                            <td>Geolocation data.</td>
                            <td>
                                <Paragraph>Physical location or movements.</Paragraph>
                            </td>
                            <td>
                                <Paragraph>TaxFileApp only collects and uses location information in relation to providing tax related services.</Paragraph>
                            </td>
                            <td>
                                Yes
                            </td>
                        </tr>
                        <tr>
                            <td>Sensory data.</td>
                            <td>
                                <Paragraph>Audio, electronic, visual, thermal, olfactory, or similar information.</Paragraph>
                            </td>
                            <td>
                                <Paragraph>TaxFileApp does not collect this category of information.</Paragraph>
                            </td>
                            <td>
                                No
                            </td>
                        </tr>
                        <tr>
                            <td>Professional or employment-related information.</td>
                            <td>
                                <Paragraph>Current or past job history or performance evaluations.</Paragraph>
                            </td>
                            <td>
                                <Paragraph>TaxFileApp only collects and uses professional and employment history in relation to providing tax related services.</Paragraph>
                            </td>
                            <td>
                                Yes
                            </td>
                        </tr>
                        <tr>
                            <td>Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
                            <td>
                                <Paragraph>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</Paragraph>
                            </td>
                            <td>
                                <Paragraph>TaxFileApp does not collect this category of information.</Paragraph>
                            </td>
                            <td>
                                No
                            </td>
                        </tr>
                        <tr>
                            <td>Inferences drawn from other Personal Information.</td>
                            <td>
                                <Paragraph>Profile reflecting a person’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</Paragraph>
                            </td>
                            <td>
                                <Paragraph>TaxFileApp does not collect this category of information.</Paragraph>
                            </td>
                            <td>
                                No
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <Paragraph class="inner-top-caption mTop10">Not included in the definition of Personal Information is information that is publicly available, information that has been de-identified or aggregated, or information exempt from the purview of the CCPA, such as</Paragraph>
                <ul class="separate_list">
                    <li>Protected Health Information (“PHI”) governed and protected by the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”), and the California Confidentiality of Medical Information Act (“CMIA”) or clinical trial data; and</li>
                    <li>Personal Information regulated by certain industry-specific privacy laws, such as the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA), California Financial Information Privacy Act (FIPA), and the Driver’s Privacy Protection Act of 1994.</li>
                </ul>
            </div>
            <div class="terms-box-inner">
                <h4>Deletion Request Rights</h4>
                <Paragraph>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</Paragraph>
                <Paragraph>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</Paragraph>
                <ul class="number-list">
                    <li><span>1.</span> Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</li>
                    <li><span>2.</span> Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
                    <li><span>3.</span> Debug products to identify and repair errors that impair existing intended functionality.</li>
                    <li><span>4.</span> Exercise free speech, ensure the right of another consumer to exercise their free speech rights or exercise another right provided for by law.</li>
                    <li><span>5.</span> Comply with the California Electronic Communications Privacy Act</li>
                    <li><span>6.</span> Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.</li>
                    <li><span>7.</span> Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
                    <li><span>8.</span> Comply with a legal obligation.</li>
                    <li><span>9.</span> Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
                </ul>
            </div>
            <div class="terms-box-inner">
                <h4>Exercising Access, Data Portability, and Deletion Rights</h4>
                <Paragraph>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by either:</Paragraph>
                <ul class="separate_list">
                    <li>Calling us at (888) 479-0979 or email a request to <a href="mailto:support@taxfile.app" class="textLink">support@taxfile.app</a></li>
                </ul>
                <Paragraph>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf may make a verifiable consumer request related to your personal information.</Paragraph>
                <Paragraph>You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</Paragraph>
                <ul class="separate_list">
                    <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</li>
                    <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
                </ul>
                <Paragraph>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require you to create an account with us. We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</Paragraph>
            </div>
            <div class="terms-box-inner">
                <h4>Response Timing and Format</h4>
                <Paragraph>We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily usable and should allow you to transmit the information from one entity to another entity without hindrance.</Paragraph>
                <Paragraph>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</Paragraph>
                <Paragraph><b>Non-Discrimination</b></Paragraph>
                <Paragraph>We will not discriminate against you for exercising any of your CCPA rights. As it relates to your data requests related to the CCPA, we will not:</Paragraph>
                <ul class="separate_list">
                    <li>Deny you goods or services.</li>
                    <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties based on</li>
                    <li>Provide you a different level or quality of goods or services.</li>
                    <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                </ul>
            </div>
            <div class="terms-box-inner">
                <h4>Request your PII Information</h4>
                <Paragraph>In compliance with California Civil Code, to request your personal data report or to delete your data with us, click the button below:</Paragraph>
                    <div class="taC">
                        <a href="https://www.taxfile.app/ccpa/">Request your personal data</a>
                    </div>
            </div>
            <div class="terms-box-inner">
                <h4>Changes to Privacy Notice</h4>
                <Paragraph>From time to time, we may change this Privacy Notice and will notify you of material changes by posting the changed or modified Privacy Notice on our Site. Any changes will be effective immediately upon the posting of the revised Privacy Notice unless otherwise specified. Your continued use of our Site following the posting of changes constitutes your acceptance of such changes.</Paragraph>
            </div>
            <div class="terms-box-inner">
                <h4>Contact Information</h4>
                <Paragraph>If you have any questions about the Privacy Notice or our privacy practices, please contact us at:</Paragraph>
                        <b class="fw500">Phone</b>: (888) 479-0979
                        <b class="fw500">Email</b>: <a href="mailto:support@taxfile.app" class="textLink">support@taxfile.app</a>
            </div>
        </Container>
    );
};

export default CPPA;
