import * as React from 'react';

import {
    BulletTitle,
    BulletWrapper,
    Column1,
    Column2,
    InfoContainer,
    InfoRow,
    TopLine,
    CardWrap, TableRow, HeadLine, PricingWrapper, UnorderedListWrapper,
    CalculatePrice, Input, Select, Option, Bold, CalculateText
} from '../InfoSection/InfoElements';
import {FaCheck} from "react-icons/fa";
import {useState} from "react";


const PricingSection = (pricing) => {

    const [filers, setFilers]= useState(1);
    const [total, setTotal]= useState(1.75 * filers);



    const options = [
        { label: 'Federal eFile', value: 1.75 },
        { label: 'Federal & State eFile', value: 2.75 },
        { label: 'Federal eFile & Mail', value: 3.75 },
        { label: 'Federal, State eFile & Mail', value: 4.75 },
        { label: 'Print Copy 1 and Copy B', value: 0.00 },
    ];

    const [service, setService] = React.useState(1.75);

    const handleChange = (event) => {
        setService(event.target.value);
        setTotal(filers * event.target.value);
    };

    function calculate(event) {
        setFilers(event.target.value);
        setTotal(event.target.value * service);
    }

    return (
        <>

            <InfoContainer lightBg={pricing.lightBg} id={pricing.id}>
                <PricingWrapper>
                    <HeadLine>{pricing.topLine}</HeadLine>

                    <CalculateText>
                        Enter the number of forms and select a filing option to calculate the price
                    </CalculateText>

                    <CalculatePrice>
                        <Input id='pricing-input' value={filers} onChange={calculate}/>
                        <p> Form with </p>
                        <Select id='pricing-select'  value={service} onChange={handleChange}>
                            {options.map((option) => (

                                <Option value={option.value}>{option.label}</Option>

                            ))}
                        </Select>
                        Total is <Bold>${total}</Bold>
                        <p>(${service} each)</p>
                    </CalculatePrice>


                    <CardWrap>
                        <TopLine>Pricing</TopLine>
                        <TableRow darkBg={true}>
                            <Column1>Federal eFile </Column1>
                            <Column2>$1.75 / Form</Column2>
                        </TableRow>
                        <TableRow>
                            <Column1>State eFile</Column1>
                            <Column2>$1.00 / Form</Column2>
                        </TableRow>
                        <TableRow darkBg={true}>
                            <Column1>Mail</Column1>
                            <Column2>$2.00 / Form</Column2>
                        </TableRow>
                        <TableRow>
                            <Column1>Copy 1 and Copy B</Column1>
                            <Column2>Free Print with eFile</Column2>
                        </TableRow>
                    </CardWrap>
                </PricingWrapper>
            </InfoContainer>

        </>
    )
}


export default PricingSection;




