import styled from "styled-components";

export const DownloadContainer = styled.div`
  background: #fff;
  display: block;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: auto auto;
  z-index: 1;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const CardWrap = styled.div`
  //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 60%;
  border-radius: 10px;
  padding: 30px 0;
  text-align: center;
  background-color: #fff;
  margin: 20px auto;

  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 20px 0;
    
  }
`

export const CardWrapDiscount = styled.div`
  //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 60%;
  border-radius: 10px;
  padding: 30px 0;
  text-align: center;
  background-color: #fff;
  margin: 20px auto;
  border: solid;
  order-width: 4px 4px 4px 4px;


  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 20px 0;

  }
`

export const DiscountClose =styled.div`
  position: relative;
  font-size: 24px;
  cursor: pointer;
 padding-top: 15px;
  `

export const DemoImage = styled.img`
  padding: 10px;
  width: 100%;
`

export const NavBtnLink = styled.a`
  border-radius: 50px;
  background: #D65E0B;
  white-space: nowrap;
  padding: 10px 22px;
  margin: 20px 20px;
  color: #010606;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  width: 200px;
  text-align: center;
  color: #fff;
  
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #184FA3;
    color: #fff;
    
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
    width: 30px;
  }
  
`;

export const DownloadText = styled.h4`
  margin: 15px;
  font-size: 16px;
`
