import {
    HeroBg,
    HeroContainer,
    HeroContent,
    HeroH1, HeroH2,
    HeroP,
} from './HeroSectionElements';
import Video from '../../videos/Tax-File-App-Features.mp4'

const HeroSection = () => {

    return (
        <>
            <HeroContainer>
                <HeroContent>
                    <HeroH1>File 1099 & W-2 Forms Online</HeroH1>
                    <HeroH2>With Intuitive User Friendly Online Dashboard or Desktop Software</HeroH2>
                    <HeroP>
                        efile tax forms quickly, efficiently and securely for the lowest price.
                    </HeroP>
                </HeroContent>
                <HeroBg>
                    <video
                        controls
                        autoPlay
                        muted
                    >
                        <source src={Video}  type="video/mp4"/>
                    </video>
                </HeroBg>
            </HeroContainer>
        </>
    )
}

export default HeroSection;
