import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbar/Navbar';
import React, {useEffect, useState} from 'react';
import HeroSection from '../components/hero/HeroSection';
import {Pricing, Features, Testimonials} from '../components/InfoSection/Data';
import Forms from '../components/forms/Forms';
import Footer from '../components/footer/Footer';
import FeaturesSection from '../components/features/Features';
import PricingSection from '../components/pricing/PricingSection';
import Contact from "../components/contact/contact";
import toast from "react-hot-toast";
import {OfferButton} from "../components/ButtonComponent";
import {CardWrapDiscount, DiscountClose} from "./DownloadElements";
import {Header} from "../components/PrivacyTerms";
import {ModalSubtitle, ModalText} from "../components/InfoSection/InfoElements";
import TestimonialsSection from "../components/testimonials/TestimonialsSection";


const Home = () => {

    const [isOpen, setIsOpen] = useState(false);
    // useEffect(() => {
    //     handleOpen();
    // })


    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const handleOpen = () => {
        const timer =  setTimeout(() => {
            customToast();
        }, 60000)
        return () => clearTimeout(timer);
    }

    const handleClose = () => {
        toast.dismiss();
    }

    const customToast = () => {
        toast.custom(
            <>
            <CardWrapDiscount>
                <Header> Still Thinking.. </Header>
                <ModalSubtitle>Your first eFile is on us, no credit card required. Just send us an email to support@taxfile.app and we will add 1 eFile credit to your account.</ModalSubtitle>
                <ModalText>We reply in about 20 minutes during the business hours</ModalText>
                <DiscountClose>
                    <OfferButton onClick={handleClose}> Sure! </OfferButton>
                </DiscountClose>
            </CardWrapDiscount>
            </>
        , {duration: 999999});
    };



    return (
      <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <HeroSection />
        <PricingSection {...Pricing} />
        <FeaturesSection {...Features} toggle={toggle}/>
        <Forms/>
        <TestimonialsSection {...Testimonials}/>
        <Contact/>
        <Footer/>
      </>
  );
};

export default Home;
