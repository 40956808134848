import {Subtitle} from "../components/InfoSection/InfoElements";
import {Bullet, BulletInfo, Container, Header, Paragraph, SubHeader, Title} from "../components/PrivacyTerms";

const PrivacyPolicy = () => {
    return (
        <Container>
            <Header>TaxFile Privacy Statement</Header>
            <SubHeader>Last Updated: March 23, 2023</SubHeader>

            <Paragraph>We at TaxFile.App ("TaxFileApp") respect your privacy and the confidentiality of your
                personal information. This privacy policy explains what information TaxFileApp collects, how we
                use and disclose that information, and how users can exercise their rights concerning their information.
                This privacy policy applies to all our websites, web applications, mobile apps, services, and
                applications that link to it ("Services").</Paragraph>

            <Title>Changes to Privacy Policy</Title>
            <Paragraph>We may change this privacy policy from time to time. If we make changes, we will notify you by
                revising the date at the top of the policy and, in some cases, send you an email notification on the
                changes. We encourage you to review the privacy policy whenever you access the services or otherwise
                interact with us to stay informed about our information practices.</Paragraph>
            <Title> Information Collected </Title>
            <Paragraph> We do not sell or rent your personal information to anyone for promotional or marketing
                purposes. However we may collect personal information about you from the following categories and
                sources to complete your IRS return(s):</Paragraph>

            <Bullet> <BulletInfo> Information provided when you create an account or request services or information
                from us, such as name, address, email address, phone number, and payment information. We request your
                phone number to communicate the status of your return filed with the IRS through a text
                message. </BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Information provided through user feedback, community discussions, chats, and other
                interactions, such as surveys, through our sites. </BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Information provided when administering technical or customer support for you to
                prepare your tax return or for other services. </BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Information provided by your system as you interact with our site(s), such as your IP
                address and browser information. </BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Information provided by you when you upload a form or other file, from your desktop or
                mobile device, we collect and store those files and/or data securely. </BulletInfo> </Bullet>
            <Bullet> <BulletInfo> If you decide to pay for our services, we may request additional information,
                including payment information. If you pay using a credit card, though we will collect financial
                information such as credit card number and expiration date, we do not store the credit card information
                at our end. Instead, we use the services provided by the authorized payment gateway to authenticate your
                identity and approve the payment. </BulletInfo> </Bullet>

            <Paragraph>We may also collect:</Paragraph>

            <Bullet> <BulletInfo> Information from your use of our referral service to tell a friend about our site, we
                will ask you for your friend's name and email address. </BulletInfo> </Bullet>
            <Bullet> <BulletInfo> Information from publicly available sources or third parties, such as when we partner
                with other companies or use service providers to help us optimize our marketing
                activities. </BulletInfo> </Bullet>
            <Paragraph> Certain personal information is required to subscribe and effectively use our services. If you
                ask us to delete such information or do not consent to our collection and use of this information, this
                may result in termination of your user account and/or your ability to use the services. </Paragraph>

            <Paragraph> If you are a resident of California and you are seeking to exercise rights under the California
                Consumer Protection Act (CCPA), please see the section below titled “Your California Privacy
                Rights.” </Paragraph>

                <Title>Information Collected Automatically </Title>
            <Paragraph> TaxFileApp collects information about your browser or device as it interacts with the services. This
            information includes your IP address and device identification information. We log information about your
            use of the services, including the type of browser you use, access times, pages viewed, your IP address, and
            the page you visited before navigating to our services. We also collect information about the computer or
            mobile device you use to access our services, including the hardware model, operating system version, and
            mobile network information. We may combine this automatically-collected information with other information
            we collect about you. We do this to improve the services we offer you and to improve marketing, analytics,
            and site functionality. If we send you a communication, such as an electronic mail, we may collect available information regarding your receipt and review of such communication. </Paragraph>

                <Paragraph> We and our service providers use cookies or similar technologies to collect information about the use of our
            services, analyze trends, administer the website, track users’ movements around the website, and gather
            demographic information about our user base. Cookies are small data files stored on your hard drive or in
            device memory that help us improve our services and your experience, enhance security, see which areas and
            features of our services are popular and count visits. Web beacons are electronic images that may be used in
            our Services or emails and help deliver cookies, count visits, and understand usage of our Services. You may
            elect to have your browser not accept cookies; however, this may disable or render unusable some of the
                        features of the Services. </Paragraph>

            <Paragraph> For marketing-related activities, TaxFileApp may monitor and collect information posted on our
            accounts or linked to us on third party operated social networks or other web offerings (such as Twitter,
                    LinkedIn, Facebook, Instagram, or Google). </Paragraph>

            <Paragraph> We use analytics software to allow us to understand the functionality of our services better. This software
            may record information such as how often you use the application, the events that occur within the
            application, aggregated usage, performance data, and where the application was downloaded from. We do not
            link the information we store within the analytics software to any personally identifiable information you
            submit within the application. You may turn off location services at any time by going to your device
                    settings and toggling off background location. </Paragraph>

                <Title> Information Use </Title>
            <Paragraph> TaxFileApp uses your information primarily to provide you with a personalized Internet or mobile
            application e-filing experience that delivers the resources, services, and information most relevant and
            helpful to our users. We do not share any customer information provided with others unless explicitly stated
                    in this Privacy Policy or when required by law. </Paragraph>

            <Paragraph> As part of the tax filing process, the IRS and State Tax agencies require TaxFileApp to request the
            email address of the person electronically filing a tax return. If an email address is collected, it is
            provided by TaxFileApp to only the appropriate IRS/State Tax agencies. As a method for detecting fraud
            in connection with e-filing, the IRS also requires TaxFileApp to provide the Internet Protocol
                    information (IP address) of the computer from which the return originated. </Paragraph>

            <Paragraph> We also use the information we collect to monitor and investigate fraud, identity theft, and other illegal
                    activity and to personalize and improve the Services. </Paragraph>

            <Paragraph> TaxFileApp values the privacy and security of consumer information and unless we have explicit
                    consumer consent, and allowed by Section 301-7216 of the Internal Revenue Service’s code, we do not: </Paragraph>

                <Bullet> <BulletInfo> Sell or rent consumer personal information; </BulletInfo> </Bullet>
                        <Bullet> <BulletInfo> Use consumer personal information to market non-tax preparation products or services to consumers; or  </BulletInfo> </Bullet>
                                <Bullet> <BulletInfo> Share consumer personal information with outside third parties to be used for their marketing purposes. </BulletInfo> </Bullet>


                <Title> Disclosure and Sharing of Information </Title>
            <Paragraph> We disclose Tax Return Information in accordance with your requests. For example, when you direct us to file
            a tax return with the IRS or to a state taxing authority or you provide your express consent to disclose
                    information to a third party offering its own product or service. </Paragraph>

            <Paragraph> In certain situations, TaxFileApp may be required to disclose personal data in response to lawful
            requests by public authorities, including to meet national security or law enforcement requirements. We also
                    may share information about you as follows: </Paragraph>

                <Bullet> <BulletInfo> With vendors, consultants and other service providers who are required to use and disclose such
            information only to perform services on our behalf; </BulletInfo> </Bullet>
                        <Bullet> <BulletInfo> To taxing authorities, law enforcement, government agencies, and others if we believe disclosure is in
            accordance with any applicable law, regulation or legal process, or as otherwise required by any applicable
            law, rule or regulation; </BulletInfo> </Bullet>
                                <Bullet> <BulletInfo> If we believe your actions are inconsistent with the spirit or language of our user agreements or
            policies, or to protect the rights, property, and safety of TaxFileApp or others; and/or </BulletInfo> </Bullet>
                                        <Bullet> <BulletInfo> In connection with, or during negotiations of, any merger, sale of company assets, financing or
            acquisition of all or a portion of our business by another company. </BulletInfo> </Bullet>

                <Paragraph> We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.
            Examples may include but are not limited to: the total number of users served by a TaxFileApp
            product/service, general age group ratios, summary reports of tax preparation practices across large groups
                        of customers, and other statistical data relating to tax preparation and electronic filing service. </Paragraph>

                <Title> Links to Other Websites </Title>
                <Paragraph>  Our Services may contain links to third party websites. Each of these links is clearly identified and will
            direct you from TaxFileApp's website to a third-party site. We do not direct or oversee the policies,
            procedures, or other practices for companies other than TaxFileApp. We do not endorse the products and
            services that are offered by third party companies. Some of these third-party sites may collect information
            about you or request sensitive, personal information from you. If accessing a third-party site via a link,
            you will be subject to the policies and procedures that have been established by the third party. We
            encourage you to review their privacy policies to learn more about what information is collected, how it is
                        used, and how it is stored. </Paragraph>

                <Paragraph> You can choose to permit or restrict third-party websites that integrate with our Services. If you elect,
            you may enter your credentials to permit files to be imported to TaxFileApp. In doing so,
            TaxFileApp may receive your username and email address for such third party websites, along with
            additional information that the application has elected to make available to TaxFileApp to facilitate
                        the integration. </Paragraph>

                <Paragraph> Our Services include social media features, such as the Facebook Like button and widgets, such as the share
            this button or interactive mini-programs that run on our site. These features may collect your IP address,
            which page you are visiting on our site, and may set a cookie to enable the feature to function properly.
            Social media features and widgets are either hosted by a third party or hosted directly through our
            Services. Your interactions with these features are governed by the privacy policy of the company providing
                        it. </Paragraph>


                <Subtitle> Communications and Opt-Out Options </Subtitle>
            <Paragraph> We may periodically send you communications or otherwise contact you about new products, services, or
            upcoming events. You may choose to be removed from our email mailing list by following the instructions in
            those emails or by contacting TaxFileApp using one of the methods listed at the top of this page
            (email or phone) to request that you be removed from our marketing list. Note, however, that even if you
            opt-out of receiving marketing communications, we will still contact you for non-marketing purposes (such as
            account information, feedback or research purposes, legal notices, account security, bug alerts, and/or
                    changes that may impact your ability to use Communicat products and services). </Paragraph>

                <Paragraph> Upon request (see “For Further Information” section), TaxFileApp will provide you with information
            about whether we hold any of your personal information. You can access, correct or deactivate your account
                    information by visiting &nbsp;<a target='_blank'  href='https://www.TaxFile.App' rel="noreferrer">www.TaxFile.App </a>&nbsp; and selecting "Sign In" or by contacting us at the email
            address provided above. In certain jurisdictions, you have the right to request access and receive
            information about the Personal Information we maintain about you, even if you are not a registered user, to
            update and correct inaccuracies in your Personal Information, or to have the information deleted. We will
                        respond to your request within a reasonable timeframe. </Paragraph>

                <Paragraph> If you delete or deactivate your account, you may no longer be able to access any of the information
            associated with that account including the information you provided through associated applications or other
            Services. If you choose an available option to deactivate your account, rather than deleting your account,
            we retain your account information in our archives even though it is no longer accessible online. We keep
            all printed or e-filed returns and related information as necessary to comply with our legal and contractual
                        obligations, resolve disputes, and enforce our agreements. </Paragraph>

                <Title> Security Measures </Title>
                <Paragraph> We work to protect your personal information from loss, misuse, or unauthorized access, alteration, and
            destruction by using industry-standard safeguards, procedures, and practices. These safeguards include
            physical, logical, and technical protections for physical security; information security; and additional
            measures to restrict access to data we hold in physical and electronic forms. For example, we restrict
            access to personal information to those on a need to know basis, and we encrypt credit card information when
            it is transmitted to us. Your access to the Services is password-protected with complex password
            requirements, security questions, and authentication by email or mobile phone. You should use a unique
                        password and should not share your password with anyone. </Paragraph>

                <Title> Interest-Based Ads </Title>
                <Paragraph> We partner with third parties to either display advertising on our website or to manage our advertising on
            other sites. Our third-party partners may use technologies such as cookies to gather information about your
            activities on this website and other sites to provide you advertising based upon your browsing activities
            and interests. Data gathered from one site may be combined with data from other websites, mobile
                        applications, and sources. </Paragraph>

                <Title> Your California Privacy Rights </Title>
                <Paragraph> If you are a California resident, California law may provide you with additional rights regarding our
            collection and use of your Personal Information. To learn more about your California privacy rights, visit
                    the Privacy Notice for California Residents at &nbsp;<a target='_blank'  href='https://www.TaxFile.App/ccpa' rel="noreferrer"> https://www.TaxFile.App/ccpa-privacy-policy.</a></Paragraph>

                <Title> For Further Information </Title>
                <Paragraph> For questions about our privacy policy or practices, you may contact us at +1 (888) 479-0979 or
                    &nbsp;<a href='mailto:support@TaxFile.App'>support@TaxFile.App</a> &nbsp;</Paragraph>

        </Container>
    );
};

export default PrivacyPolicy;