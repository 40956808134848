import React from 'react';
import {
    ServiceP,
    ServicesCard,
    ServicesContainer,
    ServicesH1,
    ServicesH2,
    ServicesWrapper
} from "./FormsElements";

const Forms = () => {
    return (
        <ServicesContainer id='forms'>
            <ServicesH1>Forms</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesH2>1099 Forms</ServicesH2>
                    <ServiceP>File all 1099 forms online including 1099-MISC, 1099-NEC,and 1099-K and their respective Correction Forms and State Filings.</ServiceP>
                    <ServiceP>Check out this <a href='https://www.youtube.com/watch?v=OiByE_cMoeo' target='_blank' rel="noreferrer">video</a> on How To File 1099 MISC in 90 seconds with TFA Tax File App</ServiceP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesH2>Wage Tax Forms</ServicesH2>
                    <ServiceP>Save time and money! E-file your W2 tax forms for the lowest price with our IRS-authorized platform. Simple, secure, and stress-free eFile. </ServiceP>
                    <ServiceP><a href='https://support.taxfile.app/hc/en-us/articles/21476510941715' target='_blank' rel="noreferrer">Here </a> is the instructions on how to file w2 with Tax File App.</ServiceP>
                </ServicesCard>
            </ServicesWrapper>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesH2>Correction Forms</ServicesH2>
                    <ServiceP>Tax File App (TFA) support corrections for the last two years for all 1099, 1098, and w2 forms, supporting last five years will be available soon. </ServiceP>
                    <ServiceP><a href='https://support.taxfile.app/hc/en-us/articles/21319526225171-How-do-I-file-1099-Corrections-in-TaxFileApp-' target='_blank' rel="noreferrer">Check out </a> the instructions on how to correct 1099 tax forms.</ServiceP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesH2>Extension Forms</ServicesH2>
                    <ServiceP>We generate Form 8809 for you for free. Tax File App will only provide the PDF for printing, and we will NOT file the form with the IRS.</ServiceP>
                    <ServiceP><a href='https://support.taxfile.app/hc/en-us/articles/24184385988371-How-to-file-an-extension-of-time-for-a-tax-form-' target='_blank' rel="noreferrer">Here </a> is the instructions on how to file an extension. </ServiceP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    );
};

export default Forms;
