import styled from "styled-components";


export const Container = styled.div`
  padding: 20px;
`
export const Header = styled.h1`
  margin-bottom: 5px;
  text-align: center;
`;

export const SubHeader = styled.h6`
  margin-bottom: 15px;
`;

export const Title = styled.h4`
  margin: 25px 0;
`;

export const Paragraph = styled.p`
  margin: 10px 0;
`;

export const Bullet = styled.li`
`;

export const BulletInfo = styled.div`
  padding-left: 15px;
`;