import React, {useRef, useState} from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import toast, {Toaster} from "react-hot-toast";
import {Divider, ServicesH1, ServicesWrapper} from "../forms/FormsElements";
import {BulletTitle, BulletWrapper, TextWrapper} from "../InfoSection/InfoElements";
import {FaEnvelope, FaPhone} from "react-icons/fa";

const Contact = () => {
    const form = useRef();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });


    const sendEmail = (e) => {

        e.preventDefault();
        emailjs
            .sendForm(
                "service_0l6cd0b",
                "template_ke5tg8s",
                form.current,
                "FHA5vycDCSn_stS61"
            )
            .then(
                (result) => {
                    toast.success('Thanks for your request!');
                    console.log(result.text);
                    console.log("message sent");
                    setFormData({
                        name: '',
                        email: '',
                        message: ''
                    })
                },
                (error) => {
                    console.log(error.text);
                }
            );



    };

    return (
        <StyledContactForm id='contacts'>
            <div><Toaster/></div>
            <ServicesH1>Contact us</ServicesH1>
            <form ref={form} onSubmit={sendEmail}>
                <label>Name</label>
                <input type="text" name="from_name" value={formData.name} onChange={(e) => setFormData({ name: e.target.value })}/>
                <label>Email</label>
                <input type="email" name="from_email" value={formData.email} onChange={(e) => setFormData({ email: e.target.value })} />
                <label>Message</label>
                <textarea name="message" value={formData.message} onChange={(e) => setFormData({ message: e.target.value })}/>
                <input type="submit" value="Send" />
            </form>

            <Divider/>

            <ServicesWrapper>
                <TextWrapper>
                    <BulletWrapper>
                        <FaEnvelope/>
                        <BulletTitle>support@taxfile.app</BulletTitle>
                    </BulletWrapper>
                    <BulletWrapper>
                        <FaPhone/>
                        <BulletTitle>+1 (888) 479-0979 </BulletTitle>
                    </BulletWrapper>
                </TextWrapper>
            </ServicesWrapper>
        </StyledContactForm>
    );
};

export default Contact;

// Styles
const StyledContactForm = styled.div`
  width: 400px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;

  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgb(5, 53, 88);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgb(5, 53, 88);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(214, 94, 11);
      color: white;
      border: none;
    }
  }
`;