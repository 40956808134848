import React from 'react';
import {Bullet, BulletInfo, Container, Header, Paragraph, SubHeader, Title} from "../components/PrivacyTerms";

const CookiesPolicy = () => {
    return (
        <Container>
            <Header>Cookies Policy</Header>
            <SubHeader>Last Updated: March 23, 2023</SubHeader>
            <Paragraph>TaxFile maintains and updates its policies as needed. Here is an overview of the TaxFile cookies
                policy, how we use them, and your options for managing them. </Paragraph>

            <Title>Cookies</Title>
            <Paragraph>A cookie is a small file created by the websites you visit and stored on your device, whether
                that is a
                computer, mobile device, etc. They serve different purposes, mainly they remember your preferences so
                that
                when you return to the website it will function according to your preferences.</Paragraph>

            <Title>Types of Cookies</Title>
            <Paragraph>There are several types of cookies available.</Paragraph>

            <Paragraph>Based on the domain that creates, the cookies are classified as:</Paragraph>
            <Bullet> <BulletInfo> First-party cookies - The cookies that are created and stored directly by the website or domain that
                    you
                    have visited.
            </BulletInfo></Bullet>
                <Bullet> <BulletInfo> Third-party cookies - The cookies that are created and stored by third parties (domains other than
                    the one
                    which you have visited).
                </BulletInfo></Bullet>

            <Paragraph>Based on the storage duration, the cookies are classified as:</Paragraph>
            <Bullet> <BulletInfo>Permanent cookies- The cookies that are created and stored for a long time on your device (until you
                    manually delete them or until the expiration date set by the publisher)
            </BulletInfo></Bullet>
                <Bullet> <BulletInfo>Session cookies - The cookies that are created and stored temporarily until the end of the
                    particular
                    session.
                </BulletInfo></Bullet>
           <br/>
            <Title>How Does TaxFile Use Cookies?</Title>
            <Paragraph>TaxFile creates and stores cookies (both the first-party and third-party) on all our users' devices for the
                following purposes:</Paragraph>

            <h5>Functionality (First-Party Cookies)</h5>
                <Bullet> <BulletInfo>To ensure a secure and seamless web session while you are logged in.</BulletInfo></Bullet>
                <Bullet> <BulletInfo>To align with your preferences (Language, Location, Login information).</BulletInfo></Bullet>
                <Bullet> <BulletInfo>This allows you to use our website and all its features without any obstacles.</BulletInfo></Bullet>
<br/>
            <h5>Analytics (Third-Party Cookies)</h5>
                <Bullet> <BulletInfo>To collect data to track the use of our services and evaluate the effectiveness of our campaigns.
                </BulletInfo></Bullet>
                <Bullet> <BulletInfo>To monitor and analyze the performance of our website and services.</BulletInfo></Bullet>
                <Bullet> <BulletInfo>To understand the areas that need improvement and enhancements.</BulletInfo></Bullet>
                <Bullet> <BulletInfo>This helps us better understand your satisfaction with our services.</BulletInfo></Bullet>
            <br/>
            <h5>Advertising (Third-Party Cookies)</h5>

            <Bullet> <BulletInfo>To display personalized ads based on your interests and usage on our website. We use third-party cookies to
                tailor ads to you with content that fits your expectations.</BulletInfo></Bullet>

            <Paragraph>Here is the list of third-party cookies that TaxFile uses:</Paragraph>

            Google Analytics - <a href='https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage' target='_blank' rel="noreferrer">https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage</a>
            <br/>
            Hotjar -<a href='https://help.hotjar.com/hc/en-us/articles/115011789248-Cookies-on-hotjar-com' target='_blank' rel="noreferrer">https://help.hotjar.com/hc/en-us/articles/115011789248-Cookies-on-hotjar-com</a>
            <br/>
            Clarity - <a href='https://docs.microsoft.com/en-us/clarity/cookie-list' target='_blank' rel="noreferrer">https://docs.microsoft.com/en-us/clarity/cookie-list </a>
            <br/>
            Google and Youtube ADs - <a href='https://business.safety.google/adscookies/' target='_blank' rel="noreferrer">https://business.safety.google/adscookies/ </a>

            <Paragraph>If you wish to opt-out from any of these third-party cookies, use the links below:</Paragraph>

            Google Analytics - <a href='https://tools.google.com/dlpage/gaoptout' target='_blank' rel="noreferrer">https://tools.google.com/dlpage/gaoptout </a>
            <br/>
            Hotjar - <a href='https://www.hotjar.com/policies/do-not-track/' target='_blank' rel="noreferrer">https://www.hotjar.com/policies/do-not-track/ </a>
            <br/>
            Clarity - <a href='https://adssettings.google.com/authenticated?hl=en' target='_blank' rel="noreferrer">https://adssettings.google.com/authenticated?hl=en</a>

        </Container>
    );
};

export default CookiesPolicy;
