import {NavBtnLink, CardWrap, DemoImage, DownloadContainer, DownloadText} from "./DownloadElements";
import TFADemo from '../images/Tax-File-App-Demo.png'
import React, {useRef, useState} from "react";
import toast, {Toaster} from "react-hot-toast";
import emailjs from "@emailjs/browser";


const Download = () => {
    const form = useRef();
    const [formDownloadData, setFormDownloadData] = useState({
        name: '',
        email: '',
        message: 'Send me the download link please!'
    });

    const sendFormEmail = (e) => {

        e.preventDefault();
        emailjs
            .sendForm(
                "service_0l6cd0b",
                "template_cl4vhm7",
                form.current,
                "FHA5vycDCSn_stS61"
            )
            .then(
                (result) => {
                    toast.success('Thanks for your request!');
                    setFormDownloadData({
                        email: ''
                    })
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <>
            <DownloadContainer>
                <CardWrap>
                    <NavBtnLink id='download' href='https://dl.todesktop.com/231018va9bxadmh'> Download
                        Software</NavBtnLink>
                    <DownloadText>Works For Windows & Mac</DownloadText>
                    <div><Toaster/></div>
                    <form ref={form} onSubmit={sendFormEmail}>
                        <label style={{fontSize: '18px', paddingRight: '10px'}}>Send me the link: </label>
                        <input style={{fontSize: '18px', marginRight: '10px'}} type="email" name="from_email"
                               value={formDownloadData.email}
                               onChange={(e) => setFormDownloadData({email: e.target.value})}/>
                        <input style={{fontSize: '18px', marginRight: '10px'}} type="hidden" name="message"
                               value={formDownloadData.message}/>
                        <input style={{fontSize: '18px'}} type="submit" value="Send"/>
                    </form>
                    <DemoImage src={TFADemo}></DemoImage>
                </CardWrap>
            </DownloadContainer>
        </>
    );
};

export default Download;
