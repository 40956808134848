import styled from 'styled-components';

export const InfoContainer = styled.div`
   background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#053558')};
  
  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`
export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  //height: 650px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`

export const FeatureWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 500px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`

export const PricingWrapper = styled.div`
  display: grid;
  z-index: 1;
  height:800px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-content: center;

  @media screen and (max-width: 480px) {
    max-width: 480px;
  }
`

export const FeaturesWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  justify-content: center;
  align-content: center;
`


export const InfoRow = styled.div`
  display: grid;
  margin-top: -150px;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
  
  @media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
  }
`

export const CalculatePrice = styled.div`
  display: flex;
  grid-auto-columns: minmax(auto, 1fr);
  margin: auto;
  padding: 20px 0;
  align-items: center;

  @media screen and (max-width: 480px) {
  display: block;
  }
`

export const Input = styled.input.attrs(props => ({
    type: 'text',

}))`
  width: 75px;
  color: #3153bb;
  font-size: 18px;
  border: 1px solid #bad7de;
  background-color: #ddf4f91f;  padding: 4px 0px;

  margin-right: 20px;
  text-align: center;

  ::placeholder {
    color: #BF4F74;
  }
`

export const Select = styled.select`
  color: #3153bb;
  font-size: 18px;
  border: 1px solid #bad7de;
  background-color: #ddf4f91f;
  cursor: pointer;
  padding: 4px 10px;
  margin: 0 20px;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    margin: 0;
  }
`
export const Option = styled.option`
  color: #3153bb;
  font-size: 18px;
  border: 1px solid #bad7de;
  background-color: #ddf4f91f;
  cursor: pointer;
  padding: 8px 10px;
`

export const Bold = styled.p`
  color: #3153bb;
  font-size: 18px;
  margin: 0 20px;
  font-weight: 600;
`

export const CalculateText = styled.h1`
  color:${({darkBg}) => (darkBg ? '#fff' : '#474646' )};
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  text-wrap: normal;

  @media screen and (max-width: 480px) {
    font-size: 12px;
    line-height: 12px;
    text-wrap: normal;
    margin: 0 5px;
  }
`

export const FeatureRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};
  
  @media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
  }
`

export const TableRow = styled.div`
  background-color: ${({darkBg}) => (darkBg ? '#dae6ef' : '#f9f9f9')};
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  grid-template-areas: 'col1 col2';

}
`

export const Column1 = styled.div`
  margin-top: 8px;
  margin-bottom: 3px;
  padding: 0 15px;
  grid-area: col1;
  text-align: start;
  align-content: center;
`

export const Column2 = styled.div`
  margin-top: 8px;
  margin-bottom: 3px;
  padding: 0 15px;
  grid-area: col2;
  text-align: start;
`

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`

export const UnorderedListWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  list-style: none;
`

export const BulletWrapper = styled.div`
  display: flex;
  max-width: 540px;
  padding-top: 0;
`

export const BulletTitle = styled.div`
  max-width: 440px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  padding-left: 15px;
`

export const TopLine = styled.h2`
  color:${({darkBg}) => (darkBg ?  '#fff' : '#053558')};
  font-size: 20px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-top: 16px;
`

export const HeadLine = styled.h1`
  color:${({darkBg}) => (darkBg ? '#fff' : '#053558' )};
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-top: -110px;
  text-align: center;
  text-wrap: normal;

  @media screen and (max-width: 480px) {
    font-size: 14px;
    line-height: 14px;
    text-wrap: normal;
  }
`

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 100%;
  font-weight: 600;
   color: ${({lightText}) => (lightText ? '#c3eff6' : '#053558')};
  
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`

export const Subtitle = styled.div`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({lightTextDesc}) => (lightTextDesc ? '#fff' : '#010606')};
`

export const ModalSubtitle = styled.div`
  padding-bottom: 10px;
  font-size: 18px;
  justify-content: center;
  margin: 0 35px;
  color: ${({lightTextDesc}) => (lightTextDesc ? '#fff' : '#010606')};
`

export const ModalText = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  justify-content: center;
  margin: auto;
  color: ${({lightTextDesc}) => (lightTextDesc ? '#fff' : '#010606')};
`

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
`

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`

export const Img =styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`

export const CardWrap = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 90%;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  background-color: #ECEEF2;
  margin: 0px auto;
`
