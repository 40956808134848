import {BrowserRouter as Router, createBrowserRouter, Link, Route, RouterProvider} from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Contacts from "./pages/Contacts";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CCPA from "./pages/CCPA";
import Terms from "./pages/Terms";
import NotFound from "./pages/NotFound";
import CookiesPlolicy from "./pages/CookiesPlolicy";
import Signup from "./pages/Signup";
import Download from "./pages/Download";
import Promotion from "./pages/Promotion";

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home/>,
        },
        {
            path: "/contact-us",
            element: <Contacts/>,
        },
        {
            path: "/download",
            element: <Download/>,
        },
        {
            path: "/promo",
            element: <Promotion/>,
        },
        {
            path: "/privacy-policy",
            element: <PrivacyPolicy/>,
        },
        {
            path: "/ccpa",
            element: <CCPA/>,
        },
        {
            path: "/terms",
            element: <Terms/>,
        },
        {
            path: "/cookies-policy",
            element: <CookiesPlolicy/>,
        },
        {
            path: "*",
            element: <NotFound/>,
        },
    ]);
  return (
      <RouterProvider router={router} />


  );
}

export default App;
